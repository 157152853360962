@font-face {
  font-family: 'roboto_condensedbold';
  src: url('../fonts/roboto/robotocondensed-bold-webfont.woff2') format('woff2'),
       url('../fonts/roboto/robotocondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'roboto_condensedregular';
  src: url('../fonts/roboto/robotocondensed-regular-webfont.woff2') format('woff2'),
       url('../fonts/roboto/robotocondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}