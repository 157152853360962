@import "vars";

.wrap-numbers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
  gap: 0.2rem;
  align-content: stretch;
  width: 100%;
  max-height: 470px;
  height: $keyboardHeight;
  min-height: 280px;

  .number {
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center center;
    transition: all 0.3s;
    aspect-ratio: auto 1/1;
    height: 100%;
    font-size: 40px;
    width: 100%;
    max-width: 12vh;
    max-height: 12vh;
    background: $third;
    color: $black;
    @media (min-width: $sm) {
      font-size: 46px;
    }

    &:hover{
      background-color: darken($color: $third, $amount: 10);
      color: $secondary;
    }

    &:focus {
      color: $secondary;
      transform: scale(0.9);
    }
    &:active {
      background-color: $secondary;
      color: $white;
      transform: scale(1.2);
    }
    &.number-0 {
      grid-column: 2 / 3;
    }
    &.number-delete {
      background-color: transparent;
      grid-column: 3 / 4;
    }
  }
  .number.number-delete {
    color: $secondary;
  }
  .number.buttonChat {
    color: darken($color: $third, $amount: 20);
    grid-column: 2 / 3;
    grid-row: 5 / 6;
    @media (min-width: $sm) {
      font-size: 3rem !important;
    }

    &:not(.active) {
      cursor: not-allowed;
    }
    &.active {
      background-color: $primary;
      color: $white;
      font-size: 2.5rem;
    }
    &.active:hover {
      transform: scale(0.9);
    }
    &.active:active {
      transform: scale(1.2);
    }
  }
}

.number-delete {
  color: $secondary;
}

#cleanInput {
  background-color: transparent;
  color: $danger;
  grid-column: 1/2;
  grid-row: 4/5;
  &:hover {
    transform: scale(0.9);
  }
  &:active {
    transform: scale(1.2);
  }
}
