@import 'vars';

.InfoApp{
    border-bottom: 1px solid $secondary;
    bottom: 0;
    background: $light;
    .container{
        display: grid;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;        
        height: $headerHeight;
        grid-template-columns: 75% 25%;
    }
    h1{
        text-transform: uppercase;
        font-family: $fontBold;
        line-height: 1;
        font-size: 24px;
        transition: all .5s;

        @media (min-width: $md) {
            font-size: 28px;
        }
        span{
            color: $secondary;
        }
    }
    .slogan{
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1;
        font-size: 14px;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
    .developed{
        text-align: right;
        line-height: 1.1;
        font-size: 14px;
        color: $black;
        white-space: nowrap;
        span{
            color: $secondary;
        }
    }

}