@import 'scss-reset/_reset.scss';
@import 'fonts';
@import 'vars';

body{
    background-color: $light;
    color: $black;
    font-family: $fontBasic;
    font-size: 1.2rem;
}

a{
    color: $secondary;
    text-decoration: none;
}
.text-secondary{
    color: $secondary;
}

.container{
    margin: 0 auto;
    width: 100%;
    &:not(.no-gutter){
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @media (min-width: $xsm) {
        max-width: 350px;
    }
}

#home-page{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $headerHeight $phoneHeight auto $closureHeight;
    height: 100vh;
    gap: 0;
}