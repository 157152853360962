$fontBasic: 'roboto_condensedregular', sans-serif;
$fontBold: 'roboto_condensedbold', sans-serif;
$black: #17171f;
$light: #FCF5EB;
$white: #fff;
$primary: #25d366;
$secondary: #075e54;
$third: #ece5dd;
$danger: #da4a4a;

$xsm: 350px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xl2: 1536px;

$headerHeight: 70px;
$phoneHeight: 70px;
$closureHeight: 50px;
$keyboardHeight: calc(90vh - $headerHeight - $phoneHeight - $closureHeight);
