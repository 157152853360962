@import "vars";

#credits-page{
  background-color: $secondary;
  color: white;
  min-height: 100vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  a{
    color: $third;
  }
}

#credits-page .container {
  h2, p {
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: 400;
    .text-secondary {
      color: $third;
    }
  }

  .github-link {
    border: 2px solid #ccc;
    border-radius: 50rem;
    padding: 0.35rem 1rem;
    text-decoration: none;
    gap: 0.5rem;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}
