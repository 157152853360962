@import 'vars';

.share-icon{
    text-align: center;
    color: $secondary;
    align-self: center;
    cursor: default;
    transition: all .2s ease;
    font-size: .9rem;
    border-left: 1px solid;
    text-align: center;

    &:hover{
        color: $black;
    }
    svg{
        display: block;
        margin: 0 auto;
    }
}