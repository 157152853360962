@import 'vars';

.react-tel-input .form-control{
    font-family: $fontBasic;
    width: 100%;
    font-size: 30px;
    border: 0 none;
    height: $phoneHeight;
    line-height: $phoneHeight;
    font-weight: normal;
    text-align: left;
    background-color: $light;
    letter-spacing: -.15rem;
    padding-left: 50px;
    @media (min-width: $xsm) {
        font-size: 36px;
    }

    
    &::placeholder{
        color: #ccc;
    }
}

.react-tel-input .selected-flag:hover, 
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag{
    background-color: $light;
}
#home-page.us .react-tel-input .form-control,
#home-page.co .react-tel-input .form-control{
    font-size: 40px;
    @media (min-width: $xsm) {
       font-size: 44px;
    }
}


.react-tel-input .flag-dropdown {
    background-color: transparent;
    border: 0 none;
}